



















































import Vue from 'vue'
import { mapState } from 'vuex'

interface DocumentData {
  id: number | null
  type?: string
  title?: string
  content?: string
}

interface DocumentType {
  label?: string
  value?: string
}

export default Vue.extend({
  props: {
    showDocumentModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      busy: false,
      showModal: false,
      documentData: {} as DocumentData,
      documentTypes: [] as DocumentType[],
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    user(newVal) {
      if (newVal) {
        this.documentTypes = newVal.client.config.documentTypes
      }
    },
    showDocumentModal(newVal) {
      this.showModal = newVal
    },
  },
  mounted() {
    if (this.user) {
      this.documentTypes = this.user.client.config.documentTypes
    }
  },
  methods: {
    closeDocumentModal() {
      this.$emit('closeDocumentModal')
    },
    noSubmit() {
      return false
    },
    submit() {
      //const dataurl = btoa(this.documentData.content ?? '')
      const base64data = Buffer.from(this.documentData.content ?? '').toString('base64')
      this.$emit('sendData', "note.txt", base64data, this.documentData.title, this.documentData.type)
      this.$emit('closeDocumentModal')
    },
  },
})
